import React from "react";
import Helmet from "react-helmet";
import { Jumbotron } from "../components/Jumbotron";
import SEO from "../components/SEO";
import "../assets/css/App.css";
import "../assets/css/index.css";
import Layout from "../components/Layout";
import Contactform from "../components/ContactForm";

export default () => (
  <Layout>
    <SEO
      title="Commercial Boiler Repair"
      description="Ensure your business stays warm and operational with BBIS Heating's premier commercial boiler repair services in London. Our expert technicians deliver swift and effective solutions, keeping your heating systems at peak performance"
      keywords="central heating service london,central heating services london,london central heating services,boiler and central heating service
      ,boiler central heating service,central heating service providers,oil central heating service,central heating boiler service,gas central heating service,central
       heating service,central heating services,central heating systems london,gas central heating system installation,oil central heating system,gas central heating
        system,new central heating system,electric central heating systems,central heating system,,"
    />
    <Jumbotron imgClassName="img15"></Jumbotron>
    <div class="white-segment">
      <h1 class="heading-color">Commercial boiler repair</h1>
      <h3 class="subheading-color">
        No heating or hot water? Boiler not working?
      </h3>
      <p>
        We understand the inconvenience of your premises being without heating
        or hot water, and how detrimental it will be for the smooth running of
        your business. We will always aim to get to you the same day (or next
        working day if same day is not possible).
      </p>
      <p>
        We will provide you with a complete and prompt commercial boiler
        breakdown service, our engineers are fully qualified to work on all
        commercial gas boilers, commercial oil boilers, commercial LPG boilers
        and electric central heating systems.{" "}
      </p>
      <p>We can also repair:</p>
      <ul>
        <li>Air heaters and Radiant tube heaters</li>
        <li>Pumps and valves</li>
        <li>Pressurisation units and pressure problems</li>
        <li>HVAC units</li>
        <li>Commercial catering equipment</li>
        <li>Heating controls</li>
        <li>Swimming pool boilers</li>
        <li>And many more appliances and breakdown scenarios</li>
      </ul>
      <p>
        For a professional heating service contact us today on{" "}
        <strong>
          <a href="tel:+448002289800">0800 2289800</a>
        </strong>
      </p>
      <Contactform></Contactform>
    </div>
  </Layout>
);
